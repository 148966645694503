* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
}

.addButton {
  background: #00b6b5 !important;
}

.addButton:hover {
  background: #00b6b5 !important;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  min-width: 80vw;
}

.portfolio-container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 20px;
  min-height: 100vh;
  min-width: 99vw;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.full-gallery {
  display: flex;
  justify-content: center;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gallery-item {
  position: relative;
  margin: 15px;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  overflow: hidden;
  cursor: grab;
}

.newsgallery-item {
  border-radius: 0;
  width: 45vw;
  height: 350px;
}

.newsgallery-item img{
  object-fit: fill !important;
}
.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.25s ease;
}

.gallery-item:hover img {
  transform: scale(1.08);
}

.item-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gallery-item:hover .item-overlay {
  opacity: 1;
}

.overlay-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-buttons button {
  margin: 5px;
  border: 0;
  font-size: 14px;
  /* Adjust font size for better readability */
  border-radius: 50%;
  /* Make the button circular */
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px;
}

.overlay-buttons button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  /* Adjust hover background color */
}