.testimonial-form {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.labels-container {
  display: flex;
  margin-bottom: 30px;
  text-align: center;
}

.label-comment,
.label-by {
  text-align: center;
  color: #555;
}
.label-comment{
  min-width: 65%;
}

.label-by{
  min-width: 26%;
}

.testimonial-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 30px;
}

.input-container {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.comments {
  width: 80%;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none; 
  overflow: hidden; 
  margin-right: 30px;
  height: auto;
  min-height: 100px;
}
.author{
  width: 40%;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none; 
  overflow: hidden; 
  margin-right: 30px;
  height: auto;
}
.buttons-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.add-btn, 
.delete-btn, 
.edit-btn, 
.open-btn {
  padding: 6px 12px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-btn {
  background-color: #e74c3c;
}

.delete-btn:hover {
  background-color: #c0392b;
}

.edit-btn {
  background: #00b6b5 !important;
}

.edit-btn:hover {
  background: #00b6b5 !important;
}

.full-container {
  text-align: center;
  margin-bottom: 20px;
}

.add-button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.drag-icon {
  cursor: move;
  margin-right: 30px;
}

.testimonial-container{
  margin: 50px 0px;
}
