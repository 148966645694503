.container {
    width: 420px;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, .2);
    backdrop-filter: blur(30px);
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    color: #00b6b5;
    border-radius: 10px;
    padding: 30px 40px;
}

.container h1 {
    font-size: 36px;
    text-align: center;
}

.container .input-box {
    position: relative;
    width: 100%;
    height: 50px;

    margin: 30px 0;
}


.input-box input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    border: 2px solid rgba(225, 225, 225, .2);
    border-radius: 40px;
    font-size: 16px;
    color: #58595B;
    padding: 20px 45px 20px 20px;
}

.input-box input::placeholder {
    color: #58595B;
}

.input-box .icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
}

.container.remember-me {
    display: flex;
    justify-content: space-between;
    font-size: 14.5px;
    margin: -15px 0 15px;

}

.remember-me label input {
    accent-color: #00b6b5;
    margin-right: 4px;

}

.container button {
    width: 100%;
    height: 45px;
    background: #00b6b5;
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(34, 13, 13, 0.1);
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    font-weight: 700;
}

.container .link {
    font-size: 14.5;
    text-align: center;
    margin-top: 20px
}

.link p a {
    color: #00b6b5;
    text-decoration: none;
    font-weight: 600;
}

.link p a :hover {
    text-decoration: underline;
}

.loginButton{
    margin: 10px 0px;
}