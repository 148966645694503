.dashboard {

    min-width: 100vw;

    min-height: 100vh; /* Full height of the viewport */
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align items to the top */
    padding: 20px;
  }
  
  .header {
    text-align: center;
    margin-top: 20px; /* Adjust spacing from top */
  }
  
  .card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
  
  .card {
    color: white;
    background-color: #fff; /* Fallback for browsers that don't support gradients */
    background: linear-gradient(135deg, #43cea2, #185a9d); /* Gradient background */
    border: 1px solid #ccc;
    border-radius: 4px;
    text-decoration: none;
    color: inherit;
    transition: background-color 0.3s ease;
    overflow: hidden; /* Ensures gradient overflow doesn't break layout */
  }
  
  .card:hover {
    background-color: #008a89; /* Hover color adjustment */
  }
  
  .card-content {
    padding: 20px;
    color: white;
    width: 30vw;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .card h2 {
    margin-bottom: 10px;
  }
  