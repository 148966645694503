/* ImageDetailsDialog.css */

.image-picker-label {
    cursor: pointer;
    display: inline-block;
    padding: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.image-preview-container {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}

.image-preview {
    max-width: 100%;
    max-height: 200px;
}

.mini-image-preview{
    max-width: 50%;
    max-height: 60px;
}

.datePickerContainer{
    width: 100%;
    margin-top: 10px;
}
